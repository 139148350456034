<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
      <!--<h3>{{$t('olapdbTitle')}}</h3>-->
      <v-spacer />
      <div>
        <!-- Use v-tabs instead of v-menu -->
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <!-- Use v-tab instead of v-list-item -->
          <v-tab
            v-for="(item, i) in getItems"
            :key="i"
            :to="{ name: item.name}"
            active-class="text--primary"
            class="font-weight-bold"
          >
            <v-menu
              v-if="item.items"
              offset-y
              open-on-hover
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-2"
                  style="padding-right: 10px;"
                >{{ item.icon }}</v-icon>
                <v-icon
                  class="ml-2"
                  v-on="on"
                >
                  mdi-menu-down
                </v-icon>
                <div>{{ item.title }}</div>
              </template>
              <v-list>
                <v-list-item
                  v-for="(subItem, j) in item.items"
                  :key="j"
                  @click="switchLanguage(subItem.locale)"
                >
                  <v-list-item-title>{{ subItem.title}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <div
              v-else
            >
              {{ item.title }}
            </div>
            <!--{{ item.name }}-->
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="getItems"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    methods: {
      switchLanguage (lang) {
        let url = this.$route.path
        // console.log('原来的url = ' + url)

        // 获取域名的第一个部分，即语言项
        let currentLang = url.split('.')[0]
        // 如果域名以www开头，就把当前语言设为en
        if (currentLang === 'www') {
          currentLang = 'en'
        }

        // 把链接地址按照"/"分割成数组
        const urlArray = url.split('/')
        // 如果当前语言是英文，且选择的语言不是英文，替换域名为语言项加上.olapdb.com
        if (currentLang === 'en' && lang !== 'en') {
          urlArray[0] = lang + '.olapdb.com'
        } else if (currentLang !== 'en' && lang === 'en') {
          urlArray[0] = 'www.olapdb.com'
        } else if (currentLang !== 'en' && lang !== 'en') {
          // 如果当前语言和选择的语言都不是英文，替换域名为新的语言项加上.olapdb.com
          urlArray[0] = lang + '.olapdb.com'
        }
        // 把数组按照"/"连接成字符串
        url = 'https://' + urlArray.join('/')
        // 跳转到新的链接地址
        // window.location.href = url
        // this.$router.push(url)
        location.href = url

        // console.log('现在的url = ' + url)
      },

    },

    computed: {
      getItems () {
        return [
          {
            // name: 'Language',
            title: this.$t('menuLanguage'),
            icon: 'mdi-web',
            items: [
              { name: 'English', title: 'English', locale: 'en' },
              { name: '中文', title: '中文', locale: 'cn' },
              // { name: '日本語', title: '日本語', locale: 'jp' },
              // { name: 'Deutsch', title: 'Deutsch', locale: 'de' },
              // { name: 'Français', title: 'Français', locale: 'fr' },
              // { name: 'Español', title: 'Español', locale: 'es' },
            ],
          },
          {
            name: 'Home',
            title: this.$t('menuHome'),
            path: '',
          },
          {
            name: 'Download',
            title: this.$t('menuDownload'),
            path: 'download',
          },
          {
            name: 'License',
            title: this.$t('menuPurchase'),
            path: 'license',
          },
          {
            name: 'User Manual',
            title: this.$t('menuUserManual'),
            path: 'user-manual',
          },
          {
            name: 'Developer API',
            title: this.$t('menuApi'),
            path: 'developer-api',
          },
          {
            name: 'About',
            title: this.$t('menuAbout'),
            path: 'about',
          },
          {
            name: 'Contact Us',
            title: this.$t('menuContact'),
            path: 'contact-us',
          },
          {
            name: 'Github',
            title: 'Github',
          },
        ]
      },
    },

    data: () => ({
      drawer: null,
    }),
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
